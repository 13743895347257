export const ITEMS_PER_PAGE = 12;

export interface ISearchParams {
  preview?: string;
  environment?: "develop" | "staging" | "master" | undefined;
  query?: string;
}

export interface IDict {
  [key: string]: string;
}

export const buttonClassAnim = `
  flex relative 
  p-1
  rounded-none
  border border-transparent
  text-black text-[.875rem] font-[600]
  gap-1
  items-center
  outline-none

  after:absolute
  after:h-[1px]
  after:bottom-1
  after:left-1
  after:right-1
  after:origin-top-left
  after:bg-black
  after:transition
  after:scale-x-0
  hover:after:scale-x-100

  active:text-black-60
  active:fill-black-60
  active:after:bg-black-60

  disabled:text-black-40
  disabled:fill-black-40
  disabled:after:bg-black-40

  focus-visible:border-dijon
  focus:after:bg-black`;

export const navTriggerStyle = `inline-flex w-max items-center text-black justify-center rounded-[6px] 
p-x-2 text-[.875rem] leading-[1.5rem] font-normal transition-all outline-none focus-visible:outline-dijon 
  disabled:pointer-events-none active:outline-none relative disabled:opacity-50  
  after:absolute after:h-[1px] after:bottom-0 after:left-0 after:right-0 after:origin-top-left after:bg-black 
  after:transition after:scale-x-0 hover:after:scale-x-100`;

export const INITIAL_SEARCH_RESULTS = {
  all: [],
  solutionProducts: [],
  solutionCategories: [],
  blogs: [],
  faqs: [],
  caseStudies: [],
  pages: [],
  customization: [],
  customizationCategory: [],
  esgPage: [],
  esgImpactArticle: [],
};
export const INITIAL_SEARCH_TOTAL = {
  all: 0,
  solutionProducts: 0,
  solutionCategories: 0,
  blogs: 0,
  faqs: 0,
  caseStudies: 0,
  pages: 0,
  customization: 0,
  customizationCategory: 0,
  esgPage: 0,
  esgImpactArticle: 0,
};

export const INITIAL_SEARCH_GROUPS_PAGINATION = {
  all: 1,
  solutionProducts: 1,
  solutionCategories: 1,
  blogs: 1,
  faqs: 1,
  caseStudies: 1,
  pages: 1,
  customization: 1,
  customizationCategory: 1,
  esgPage: 1,
  esgImpactArticle: 1,
};
export const SEARCH_QUICK_LINKS = [
  { label: "Mobile Offices" },
  { label: "Flex & Premium Spaces" },
  { label: "Storage Containers" },
  { label: "Industrial Fabric Structures & Complexes" },
];

export const CONTENT_TYPES: { [key: string]: string } = {
  page: "Page",
  faqTopic: "FAQs",
  caseStudy: "case studies",
  blogArticle: "blog",
  solutionCategory: "solution category",
  solution: "solution product",
};

export type TDict = { [key: string]: string };

export const CENTER_USA = { lat: 39.72488726936953, lng: -100.75391842260707 };

interface IColorMap {
  [key: string]: {
    labelColor: string;
    bgColorClass: string;
    textColor: string;
    bg: "dark" | "light";
    border: string;
  };
}

export const fallbackColors = {
  labelColor: "text-mellow",
  bgColorClass: "bg-navy-blue",
  textColor: "text-white",
  bg: "dark",
};

export const colorsMap: IColorMap = {
  "navy-blue": {
    labelColor: "text-mellow",
    bgColorClass: "bg-navy-blue",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "navy-blue-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-navy-blue-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "navy-blue-20": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-navy-blue-20",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "navy-blue-60": {
    labelColor: "text-mellow",
    bgColorClass: "bg-navy-blue-60",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "navy-blue-80": {
    labelColor: "text-mellow",
    bgColorClass: "bg-navy-blue-80",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "royal-blue": {
    labelColor: "text-mellow",
    bgColorClass: "bg-royal-blue",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "royal-blue-80": {
    labelColor: "text-mellow",
    bgColorClass: "bg-royal-blue-80",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "royal-blue-60": {
    labelColor: "text-mellow",
    bgColorClass: "bg-royal-blue-60",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "royal-blue-20": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-royal-blue-20",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "royal-blue-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-royal-blue-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },

  "mellow-10": {
    labelColor: "text-black",
    bgColorClass: "bg-mellow-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "black-5": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-black-5",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  white: {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-white",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "dijon-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-dijon-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  dijon: {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-dijon",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  // !! old values, kept bc of Contentful
  "vivid-blue": {
    labelColor: "text-mellow",
    bgColorClass: "bg-royal-blue",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "slate-5": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-black-5",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "vivid-blue-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-royal-blue-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
  "royal-navy": {
    labelColor: "text-mellow",
    bgColorClass: "bg-navy-blue",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "royal-navy-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-navy-blue-10",
    textColor: "text-black",
    bg: "light",
    border: "border-black",
  },
  "denim-blue": {
    labelColor: "text-mellow",
    bgColorClass: "bg-royal-blue",
    textColor: "text-white",
    bg: "dark",
    border: "border-mellow",
  },
  "denim-blue-10": {
    labelColor: "text-royal-blue",
    bgColorClass: "bg-royal-blue-10",
    textColor: "text-black",
    bg: "light",
    border: "border-royal-blue",
  },
};

export const ESG_IMPACT_GROUPS = ["allPrograms", "Environmental Responsibility", "Our People", "Effective Governance"];
// export const ESG_IMPACT_GROUPS = ["allPrograms", "environmental", "social", "governance"];

export const ESG_GROUPS_MAPPING: { [key: string]: string } = {
  "Environmental Responsibility": "Environmental Responsibility",
  "Our People": "Our People",
  "Effective Governance": "Effective Governance",
};

export const INITIAL_ESG_IMPACT_RESULTS = {
  allPrograms: [],
  "Environmental Responsibility": [],
  "Our People": [],
  "Effective Governance": [],
};

export const INITIAL_ESG_IMPACT_PAGINATION = {
  allPrograms: 1,
  "Environmental Responsibility": 1,
  "Our People": 1,
  "Effective Governance": 1,
};

export const INITIAL_ESG_IMPACT_TOTAL = {
  allPrograms: 0,
  "Environmental Responsibility": 0,
  "Our People": 0,
  "Effective Governance": 0,
};

export const ESG_URL_MAPPING_TO_LABEL = [
  { url: "/about-us/sustainability-impact/overview", name: "overview" },
  { url: "about-us/sustainability-impact/environmental-impact", name: "environmentalImpact" },
  { url: "/about-us/sustainability-impact/social-impact", name: "socialImpact" },
  { url: "/about-us/sustainability-impact/governance-impact", name: "governanceImpact" },
  // { url: "/about-us/sustainability-impact/view-report", name: "viewOurEsgReport" },
  { url: "/about-us/sustainability-impact/employee-wellness-and-safety", name: "employeeWellnessSafety" },
  { url: "/about-us/sustainability-impact/community-outreach", name: "communityOutreach" },
  { url: "/about-us/sustainability-impact/sustainability", name: "sustainability" },
];

export const WORKSITE_EXPLORE_HERO_IMAGES: { [key: string]: string } = {
  "4apvbZUvzAiVWOinQREwOF": "/worksite-explorer/construction-builders-6000.webp",
  FJ79kJ8jSb040tCufXc70: "/worksite-explorer/education-government-6000.webp",
  "6m7mNwq4ickrr0ifIsPeil": "/worksite-explorer/energy-industrial-6000.webp",
  "4P5mkr372xoaY9tYsQg9Tw": "/worksite-explorer/retail-distribution-6000.webp",
  "4jCgtiKqHTmTu4fvBIG9Kq": "/worksite-explorer/manufacturing-6000.webp",
  "7x0y595LkCzmvm83OG3wTw": "/worksite-explorer/healthcare_entertainment-6000.webp",
};

export const NAVIGATION_ID = "3FrQhjYmN1Ud6vB2gjlwpi";

export const PADDING_BOTTOM_MAP: { [key: string]: string } = {
  None: "sm:py-0 py-0",
  Small: "sm:py-8 py-4",
  Medium: "sm:py-16 py-8",
  Large: "sm:py-20 py-12",
};
